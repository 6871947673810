import defaultTenantConfig from '../../tenant-config'
import { createContext, useEffect, useState, useContext } from 'react'
import { lightTheme } from '@leadrilla/pulsar'
import backend from '../../backend'
import applyVoiceUserOverride from './apply_voice_user_override'

export type Tenant =
  | 'leadrilla'
  | 'amerilife'
  | 'amerilife_test'
  | 'demo'
  | 'pipeline'
  | 'edm'
  | 'trking'
  | 'godspeed'
  | 'advocate'
  | 'pmg'
  | 'leadprodigy'
  | 'consumeraffairs'
  | 'adpl'
  | 'mfg'
  | 'ifg'

export interface TenantConfig {
  tenant: Tenant
  name: string
  title: string
  description: string
  themeColor: string
  theme: typeof lightTheme
  mapTheme: {
    0.2: string
    0.4: string
    0.6: string
    0.8: string
  }
  connected_stripe_account_id?: string
  enabledStates?: string[] // TODO - make/find a type/enum for abbreviated state names
  support_email: string
  call_campaigns: {
    ipp_idle_timeout?: number
  }
  voice_enabled: boolean
  disableMarketplace?: boolean
  signup: {
    invite_only: boolean
    collect_npn: boolean
    collect_industry: boolean
    collect_company: boolean
  }
  terms: {
    company_name: string
    site: string
    address: string
  }
  logos?: {
    // TODO: dimensions for other logos
    dashboard_main?: {
      width: string
      height: string
    }
  }
  referral_program: ReferralProgramEnabled | ReferralProgramDisabled
  custom_menu_links?: CustomMenuLink[]
  call_campaign_video_id?: string
  enable_lead_print?: boolean
  subscription?: { enabled: boolean }
  extra_terms?: {
    name: string
    url: string
  }
  teams: { enabled: boolean }
  sms_checkbox?: boolean
  hide_statewide_targeting?: boolean
  hide_radius_targeting?: boolean
  welcome_message?: string
  organizations:
    | { enabled: false }
    | {
        enabled: true
        collect_during_signup: boolean
        display_name: string
      }
}

interface ReferralProgramEnabled {
  enabled: true
  referrer_amount: number
  referee_amount: number
  required_spend_amount: number
  link_only?: boolean
}

interface ReferralProgramDisabled {
  enabled: false
}

interface CustomMenuLink {
  text: string
  icon: string
  href: string
}

/** This hook is the preferred method for fetching the tenant config. */
export const useTenantConfig = () => useContext(TenantConfigContext)

export const isLeadrilla = () => process.env.REACT_APP_TENANT === 'leadrilla'

/**
 * Only use this synchronous function if it is not possible to use the async `getTenantConfig()` function.
 *
 * This function returns the default tenant config, without any user-specific overrides.
 */
export const getNonUserSpecificTenantConfig = (): TenantConfig => {
  return defaultTenantConfig[(process.env.REACT_APP_TENANT || 'leadrilla') as Tenant]
}

/**
 * Returns the default tenant config, after applying any user-specific overrides.
 *
 * NOTE: Do not use `getTenantConfig()` inside of a React component. Instead, use the `useTenantConfig()` hook (to prevent unnecessary re-renders).
 */
export const getTenantConfig = async (): Promise<TenantConfig> => {
  const nonUserSpecificTenantConfig = getNonUserSpecificTenantConfig()

  const { body: user } = await backend.get(`/users/me`)
  if (!user) return nonUserSpecificTenantConfig

  // Apply user-specific overrides
  let tenantConfig = { ...nonUserSpecificTenantConfig }

  // Voice Override
  tenantConfig = applyVoiceUserOverride({ tenantConfig, user })

  return tenantConfig
}

export const TenantConfigContext = createContext<TenantConfig>(getNonUserSpecificTenantConfig())

export const TenantConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [tenantConfig, setTenantConfig] = useState<TenantConfig>(
    getNonUserSpecificTenantConfig() as TenantConfig
  )

  useEffect(() => {
    const fetchConfig = async () => {
      const tenantConfig = await getTenantConfig()
      setTenantConfig(tenantConfig)
    }
    fetchConfig()
  }, [])

  return (
    <TenantConfigContext.Provider value={tenantConfig}>{children}</TenantConfigContext.Provider>
  )
}
